import React from "react";

import { Box, Button, List, ListItem, Typography } from "@mui/material";
import QRCode from "qrcode";


interface BankIdMobileLoginQrProps {
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  loginFailed: boolean;
  qrCode: string | null;
  logInAgain:(event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
}

const BankIdMobileLogin: React.FC<BankIdMobileLoginQrProps> = ({
  onBackClick,
  loginFailed,
  qrCode,
  logInAgain
}: BankIdMobileLoginQrProps) => {
  const qrCodeCanvasRef = React.useRef(null);

  React.useEffect(() => {
    if (qrCode === null || qrCodeCanvasRef?.current === null) {
      return;
    }
    QRCode.toCanvas(qrCodeCanvasRef.current, qrCode, {
      width: 240,
    });
  }, [qrCode, qrCodeCanvasRef]);

  return (
    <>
      {loginFailed ? (
        <>
        <Box sx={{width:"256px",  textAlign: "center", m:"0 auto"}}>
          <Typography variant="body1" sx={{fontWeight:700}}>
          <p>QR-scanningen misslyckades. Försök igen, eller kom tillbaka senare.</p>
          </Typography>
          <Typography variant="body1" sx={{fontSize:"14px", mt:"0 !important" }}>
            <p>Kontrollera att du har den senaste versionen av BankID. Om problemet kvarstår, kontakta vår support.</p>
          </Typography>
        </Box>
        {loginFailed&&
          <Button
          onClick={logInAgain}
          disableRipple
          variant="contained"
          fullWidth
          sx={{
            left: "50%",
            transform: "translateX(-50%)",
            width:"256px",
            height:"48px",
            textTransform:'none',
            backgroundColor:"#0068E3",
            color:"white",
            mt:"8px !important",
            boxShadow: "none",
            "&:hover":{
              backgroundColor:"#3290ff",
              border:"none",
              boxShadow: "none",
            }
            }}
        >
        Försök igen
        </Button>
      }
        <Button
          variant="outlined"
          sx={{
            color:"#0068E3",
            width:"256px",
            mt: 0.5,
            textTransform:"none",
            height:"48px",
            border:"1px solid #0068E3",
            left: "50%",
            transform: "translateX(-50%)",
            "&:hover":{
              backgroundColor:"#3290ff",
              color:"white",
              border:"none"
            }
          }}
          onClick={onBackClick}
        >
          Avbryt
        </Button>
        </>
      ) : (
        <>
        <Box
          component="canvas"
          id="qrcode"
          sx={{
            minHeight: 196,
            minWidth: 196,
            ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
            mr: "auto !important",
            display: "block",
          }}
          ref={qrCodeCanvasRef}
          aria-label={"QR-kod för inloggning med BankID"}
        />
        <List 
        disablePadding={true}
        component="ol"
        sx={{
          fontSize: "14px",
          m:"0 auto 16px auto !important",
          width:"208px",
          color:"#2B3544",
          lineHeight:"17px",
          fontFamily:'Ubuntu, sans-serif'
          }}>
              <ListItem disablePadding={true}>1. Öppna din BankID-app</ListItem>
              <ListItem disablePadding={true}>2. Klicka på QR-ikonen i appen</ListItem>
              <ListItem disablePadding={true}>3. Rikta kameran mot QR-koden</ListItem>
              <ListItem disablePadding={true}>4. Följ instruktionerna i appen</ListItem>
          </List>
          <Button
            variant="contained"
            sx={{
              left: "50%",
              transform: "translateX(-50%)",
              width:"256px",
              height:"48px",
              textTransform:'none',
              backgroundColor:"#0068E3",
              color:"white",
              mt:"8px !important",
              boxShadow: "none",
              "&:hover":{
                backgroundColor:"#3290ff",
                border:"none",
                boxShadow: "none",
              }
              }}
            onClick={onBackClick}
          >
            Avbryt
          </Button>
        </>
      )}
         
    </>
  );
};

export default BankIdMobileLogin;
