import React, { MutableRefObject } from "react";

import { Button, CircularProgress, Typography } from "@mui/material";


interface BankIdThisDeviceLoginProps {
  bankIdOpened: boolean;
  loginFailed: boolean;
  setBankIdOpened: (opened: boolean) => void;
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  startBankIdLogin: (personalNumber?: string) => Promise<void>;
  bankIdUrl: MutableRefObject<string>;
  userMessage: string;
}

const BankIdThisDeviceLogin: React.FC<BankIdThisDeviceLoginProps> = ({
  bankIdOpened,
  loginFailed,
  setBankIdOpened,
  onBackClick,
  startBankIdLogin,
  bankIdUrl,
  userMessage,
}) => {
  const onOpenBankIdClick = async (
    _event: React.MouseEvent<HTMLButtonElement>
  ): Promise<void> => {
    await startBankIdLogin();
    setBankIdOpened(true);
    window.location.assign(bankIdUrl.current);
  };

  return (
    <>
      {!loginFailed &&
        (bankIdOpened ? (
          <Button
            variant="contained"
            fullWidth
            disabled
            startIcon={<CircularProgress />}
            sx={{
              width:"256px",
              // textAlign:"center",
              textTransform:"none",
              height:"48px",
              backgroundColor:"#0068E3",
              left: "50%",
              transform: "translateX(-50%)",
              }}
          >
          Väntar på BankID
          </Button>
        ) : (
          <Button 
          variant="contained"
          fullWidth 
          onClick={onOpenBankIdClick} 
          sx={{
            width:"256px",
            textTransform:"none",
            height:"48px",
            backgroundColor:"#0068E3",
            left: "50%",
            transform: "translateX(-50%)",
            boxShadow: "none",
            "&:hover":{
              backgroundColor:"#3290FF",
              boxShadow: "none",
            }
            }}>
            Öppna BankID på denna enhet
          </Button>
        ))}
      {loginFailed && <Typography variant="body1">{userMessage}</Typography>}
      <Button
        variant="outlined"
        fullWidth
        sx={{
          color:"#0068E3",
          width:"256px",
          mt: 0.5,
          textTransform:"none",
          height:"48px",
          border:"1px solid #0068E3",
          left: "50%",
          transform: "translateX(-50%)",
        "&:hover":{
          backgroundColor:"#3290FF",
          color:"white",
          border:"none"
        }
        }}
        onClick={onBackClick}
      >
        Avbryt
      </Button>
    </>
  );
};

export default BankIdThisDeviceLogin;
