import React from "react";

import {
  Box,
  Card,
  CardContent,
  Stack,
  SxProps,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import {
  cancelLogin,
  checkIfBankIdLoginStarted,
  startBankIdLogin,
} from "shared/bankidApi";
import { isMobileBrowser } from "shared/utils";
import BankIdMobileLoginQr from "./BankIdMobileLoginQr";
import BankIdThisDeviceLogin from "./BankIdThisDeviceLogin";
import BankIDBtnLogo from "./images/BankIDBtnLogo";
import { Config } from "shared/config";

interface BankIdFrameProps {
  sx?: SxProps<Theme>;
  config: Config;
}

const BankIdFrame: React.FC<BankIdFrameProps> = ({ sx = [], config }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const login_challenge = searchParams.get("login_challenge") as string;
  const login_hint = searchParams.get("login_hint") ?? "";
  const [loginMethod, setLoginMethod] = React.useState<
    "this_device" | "mobile"
  >(login_hint === "this_device" ? "this_device" : "mobile");
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [bankIdOpened, setBankIdOpened] = React.useState(false);
  const [qrCode, setQrCode] = React.useState<string | null>(null);
  const bankIdUrl = React.useRef("");
  const [userMessage, setUserMessage] = React.useState("");
  const pollingCallback = React.useRef<NodeJS.Timeout | null>(null);
  const is_only_webapp = React.useMemo(() => {
    const { is_only_webapp } = config;
    if (!is_only_webapp) return false;
    if (is_only_webapp) return is_only_webapp.toLowerCase() == "true";
    return false;
  }, [config]);

  React.useEffect(() => {
    if (login_hint === "" && isMobileBrowser()) {
      setLoginMethod("this_device");
    }
  }, [login_hint]);

  const handleChange = (
    _event: React.MouseEvent<HTMLElement>,
    newLoginMethod: "this_device" | "mobile" | null
  ) => {
    if (newLoginMethod !== null) {
      setLoginMethod(newLoginMethod);
    }
  };

  const clearCache = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    console.log("Cache Cleared");
  };

  const onBackClick = async (_event: React.MouseEvent<HTMLButtonElement>) => {
    if (login_challenge !== null) {
      try {
        await cancelLogin(login_challenge);
      } catch {
        // no meaningful error handling here, we just go back which should reset
        // everything
      }
    }
    setBankIdOpened(false);
    history.back();
  };

  const bankIdLogin = async (personalNumber?: string) => {
    await startBankIdLogin(
      login_challenge,
      pollingCallback,
      loginMethod,
      bankIdUrl,
      setBankIdOpened,
      setLoginFailed,
      setUserMessage,
      undefined,
      personalNumber,
      is_only_webapp
    );
  };

  const renderedLoginMethod = () => {
    switch (loginMethod) {
      case "this_device":
        return (
          <BankIdThisDeviceLogin
            bankIdOpened={bankIdOpened}
            setBankIdOpened={setBankIdOpened}
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            startBankIdLogin={bankIdLogin}
            bankIdUrl={bankIdUrl}
            userMessage={userMessage}
          />
        );
      case "mobile":
        return (
          <BankIdMobileLoginQr
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            qrCode={qrCode}
            // userMessage={timeoutMessage}
            logInAgain={onBackClick}
          />
        );
    }
  };

  // const titleText = () => {
  //   switch (loginMethod) {
  //     case "this_device":
  //       return "Logga in med BankID";
  //     case "mobile":
  //       return "Logga in med mobilt BankID";
  //   }
  // };

  React.useEffect(() => {
    clearCache();
    if (loginMethod === "mobile") {
      startBankIdLogin(
        login_challenge,
        pollingCallback,
        loginMethod,
        bankIdUrl,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage,
        setQrCode,
        "",
        is_only_webapp
      );
    } else if (loginMethod === "this_device") {
      checkIfBankIdLoginStarted(
        login_challenge,
        pollingCallback,
        loginMethod,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage
      );
    }
    return () => {
      if (pollingCallback.current) {
        clearTimeout(pollingCallback.current);
        pollingCallback.current = null;
      }
    };
  }, [login_challenge, loginMethod]);

  return (
    <Card variant="outlined" sx={sx}>
      <CardContent sx={{ padding: 0 }}>
        <Stack spacing={2}>
          {!loginFailed && (
            <ToggleButtonGroup
              value={loginMethod}
              onChange={handleChange}
              exclusive
              fullWidth
              sx={{
                textDecoration: "none !important",
                backgroundColor: "white",
                "& .Mui-selected": {
                  backgroundColor: "white",
                  color: "#0068E3 !important",
                  textDecoration: "none",
                },
              }}
            >
              <ToggleButton
                value="mobile"
                disableRipple={true}
                disableTouchRipple={true}
                sx={{
                  position: "relative",
                  top: "-1px",
                  left: "-1px",
                  border: "none",
                  backgroundColor: "white !important",
                  color: "#14171E",
                  "&.Mui-selected": {
                    borderTop: "4px solid #0068E3 !important",
                    textDecoration: "none !important",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "9px",
                    alignItems: "center",
                    mt: "6.5px",
                  }}
                >
                  <PhoneAndroidIcon sx={{ m: 0, color: "#003366" }} />
                  <Box
                    component="span"
                    sx={{
                      m: 0,
                      width: "49px",
                      textTransform: "none",
                      fontWeight: "700",
                      textDecoration: "none !important",
                      textAlign: "left",
                      lineHeight: "21px",
                      "&.Mui-selected": {
                        color: "#0068E3 !important",
                        textDecoration: "none !important",
                      },
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    Mobilt BankID
                  </Box>
                </Box>
              </ToggleButton>
              <ToggleButton
                value="this_device"
                disableRipple={true}
                disableTouchRipple={true}
                sx={{
                  position: "relative",
                  top: "-1px",
                  right: "-1px",
                  border: "none",
                  backgroundColor: "white !important",
                  color: "#14171E",
                  "&.Mui-selected": {
                    borderTop: "4px solid #0068E3 !important",
                    textDecoration: "none !important",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "9px",
                    alignItems: "center",
                    mt: "6.5px",
                  }}
                >
                  <BankIDBtnLogo sx={{ height: "24px", color: "#003366" }} />
                  <Box
                    component="span"
                    sx={{
                      m: 0,
                      width: "49px",
                      textTransform: "none",
                      fontWeight: "700",
                      textDecoration: "none !important",
                      textAlign: "left",
                      lineHeight: "21px",
                      "&.Mui-selected": {
                        color: "#0068E3 !important",
                        textDecoration: "none !important",
                      },
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    BankID
                  </Box>
                </Box>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {renderedLoginMethod()}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default BankIdFrame;
