import initializeTracer from "shared/tracer";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { loadConfig } from "shared/config";

loadConfig()
  .then((config) => {
    ReactDOM.render(<App config={config}/>, document.getElementById("root"));
  })
  .catch(() => {
    ReactDOM.render(<App config={{}}/>, document.getElementById("root"));
  });
